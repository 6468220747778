import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../api/supabase/client';

const WorkspaceLayout = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workspace, setWorkspace] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWorkspace = async () => {
      try {
        const { data, error } = await supabase
          .from('workspaces')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;
        setWorkspace(data);
      } catch (error) {
        console.error('Error fetching workspace:', error);
        navigate('/workspaces');
      } finally {
        setLoading(false);
      }
    };

    fetchWorkspace();
  }, [id, navigate]);

  if (loading) return <div>Loading...</div>;
  if (!workspace) return null;

  return (
    <div className="flex flex-col h-full">
      <div className="border-b border-gray-200">
        <div className="px-4 sm:px-6 lg:px-8">
          <nav className="flex space-x-4">
            <button
              onClick={() => navigate(`/workspace/${id}/overview`)}
              className="px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              Overview
            </button>
            <button
              onClick={() => navigate(`/workspace/${id}/settings`)}
              className="px-3 py-2 text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              Settings
            </button>
          </nav>
        </div>
      </div>
      
      <div className="flex-1 px-4 sm:px-6 lg:px-8 py-8">
        <Outlet context={{ workspace }} />
      </div>
    </div>
  );
};

export default WorkspaceLayout; 