import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../catalyst/dialog';
import { Field, Label } from '../catalyst/fieldset';
import { Button } from '../catalyst/button';
import { Select } from '../catalyst/select';
import { supabase } from '../../api/supabase/client';

const AssignUserDialog = ({ isOpen, onClose, workspaceId }) => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
      fetchAssignedUsers();
    }
  }, [isOpen, workspaceId]);

  const fetchUsers = async () => {
    const { data, error } = await supabase
      .from('user_meta')
      .select('id, first_name, last_name, role');
    
    if (error) {
      setError(error.message);
    } else {
      setUsers(data);
    }
  };

  const fetchAssignedUsers = async () => {
    const { data, error } = await supabase
      .from('workspace_users')
      .select('user_id, user_meta (first_name, last_name)')
      .eq('workspace_id', workspaceId);

    if (error) {
      setError(error.message);
    } else {
      setAssignedUsers(data);
    }
  };

  const handleAssignUser = async () => {
    try {
      setError(null);
      if (!selectedUserId) {
        throw new Error('Selecteer een gebruiker');
      }

      const { error } = await supabase
        .from('workspace_users')
        .insert({
          workspace_id: workspaceId,
          user_id: selectedUserId
        });

      if (error) throw error;

      fetchAssignedUsers();
      setSelectedUserId('');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      const { error } = await supabase
        .from('workspace_users')
        .delete()
        .eq('workspace_id', workspaceId)
        .eq('user_id', userId);

      if (error) throw error;

      fetchAssignedUsers();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Gebruikers toewijzen</DialogTitle>
      <DialogDescription>
        Beheer de gebruikers van deze workspace.
      </DialogDescription>
      <DialogBody>
        <Field className="mb-4">
          <Label>Gebruiker toevoegen</Label>
          <Select
            value={selectedUserId}
            onChange={(e) => setSelectedUserId(e.target.value)}
          >
            <option value="">Selecteer een gebruiker</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {`${user.first_name} ${user.last_name}`}
              </option>
            ))}
          </Select>
          <Button onClick={handleAssignUser} className="mt-2">
            Toevoegen
          </Button>
        </Field>

        <div className="mt-4">
          <h4 className="font-medium mb-2">Toegewezen gebruikers</h4>
          {assignedUsers.map((assigned) => (
            <div key={assigned.user_id} className="flex justify-between items-center py-2">
              <span>{`${assigned.user_meta.first_name} ${assigned.user_meta.last_name}`}</span>
              <Button plain onClick={() => handleRemoveUser(assigned.user_id)}>
                Verwijderen
              </Button>
            </div>
          ))}
        </div>

        {error && (
          <p className="text-red-500 text-sm mt-2">{error}</p>
        )}
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose}>Sluiten</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignUserDialog; 