import React, { useState } from 'react';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../catalyst/dialog';
import { Field, Label } from '../catalyst/fieldset';
import { Input } from '../catalyst/input';
import { Button } from '../catalyst/button';

const AddWorkspaceDialog = ({ isOpen, onClose, onWorkspaceAdded, userId }) => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    try {
      setError(null);
      if (!workspaceName.trim()) {
        throw new Error('Workspace naam is verplicht');
      }
      
      await onWorkspaceAdded(workspaceName, websiteUrl, userId);
      setWorkspaceName('');
      setWebsiteUrl('');
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Nieuwe Workspace</DialogTitle>
      <DialogDescription>
        Voeg een nieuwe workspace toe aan je account.
      </DialogDescription>
      <DialogBody>
        <Field className="mb-4">
          <Label>Workspace naam</Label>
          <Input
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            placeholder="Voer een naam in"
          />
        </Field>
        <Field>
          <Label>Website URL</Label>
          <Input
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)}
            placeholder="https://example.com"
          />
        </Field>
        {error && (
          <p className="text-red-500 text-sm mt-2">{error}</p>
        )}
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>Annuleren</Button>
        <Button onClick={handleSubmit}>Toevoegen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWorkspaceDialog; 