import { supabase } from '../api/supabase/client';

export const workspaceService = {
  async getWorkspaces() {
    const { data, error } = await supabase
      .from('workspaces')
      .select('*');

    if (error) throw error;
    return data;
  },

  async getWorkspace(id) {
    const { data, error } = await supabase
      .from('workspaces')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  },

  async createWorkspace({ name, websiteUrl }) {
    const { data, error } = await supabase
      .from('workspaces')
      .insert([{ name, website_url: websiteUrl }])
      .select();

    if (error) throw error;
    return data[0];
  },

  async updateWorkspace(id, { name, websiteUrl }) {
    const { data, error } = await supabase
      .from('workspaces')
      .update({ name, website_url: websiteUrl })
      .eq('id', id)
      .select();

    if (error) throw error;
    return data[0];
  },

  async deleteWorkspace(id) {
    // Eerst verwijderen we alle workspace users
    const { error: usersError } = await supabase
      .from('workspace_users')
      .delete()
      .eq('workspace_id', id);

    if (usersError) throw usersError;

    // Dan de workspace zelf
    const { error } = await supabase
      .from('workspaces')
      .delete()
      .eq('id', id);

    if (error) throw error;
  }
};