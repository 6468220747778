import React from 'react';

const SocialTab = ({ socialData, calculatePercentageChange }) => {
  if (!socialData) {
    return <div>Geen sociale gegevens beschikbaar.</div>;
  }

  // Voeg hier je logica toe om sociale media gegevens weer te geven
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Sociale Media Inzichten</h2>
      <div>
        {socialData.map((item, index) => (
          <div key={index} className="mb-2">
            <p className="font-medium">{item.name}</p>
            <p>Huidige maand: {item.current}</p>
            <p>Vorige maand: {item.previous}</p>
            <p>Verandering: {calculatePercentageChange(item.current, item.previous).toFixed(2)}%</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialTab;
