import React, { useState, useEffect } from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/16/solid';
import { supabase } from '../../supabaseClient';

const TechnicalTab = ({ workspaceId }) => {
  const [websiteStatus, setWebsiteStatus] = useState(null);
  const [uptimePercentage, setUptimePercentage] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);

  useEffect(() => {
    const fetchWebsiteStatus = async () => {
      setIsLoadingStatus(true);

      if (!workspaceId) {
        console.error('Workspace ID is not defined');
        setIsLoadingStatus(false);
        return;
      }

      const { data, error } = await supabase
        .from('workspaces')
        .select('uptime_robot_api_key')
        .eq('id', workspaceId)
        .single();

      if (error) {
        console.error('Error fetching API key:', error.message);
        setIsLoadingStatus(false);
        return;
      }

      const apiKey = data?.uptime_robot_api_key;

      if (apiKey) {
        try {
          const response = await fetch('https://api.uptimerobot.com/v2/getMonitors', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              'api_key': apiKey,
              'format': 'json',
              'custom_uptime_ratios': '30',  // Laat de API het uptime-percentage van de laatste 30 dagen teruggeven
            }),
          });

          const result = await response.json();

          if (result.stat === 'ok') {
            const monitor = result.monitors[0];
            setWebsiteStatus(monitor.status === 2 ? 'Online' : 'Offline');
            setUptimePercentage(Math.round(monitor.custom_uptime_ratio.split('-')[0]));  // Rond af op hele procenten
          } else {
            console.error('Failed to fetch uptime status:', result.error.message);
          }
        } catch (err) {
          console.error('Error fetching uptime status:', err.message);
        }
      } else {
        console.error('No API key found');
      }

      setIsLoadingStatus(false);
    };

    fetchWebsiteStatus();
  }, [workspaceId]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
      {/* Website Status Card */}
      <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
        <div className="flex justify-between items-center">
          <h2 className="font-medium text-zinc-900">Website status</h2>
        </div>
        <div className="mt-3 text-3xl font-semibold sm:text-2xl">
          {isLoadingStatus ? 'Loading...' : websiteStatus}
        </div>
        <div className="mt-3 text-sm sm:text-xs flex items-center">
          <span className={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm font-medium sm:text-xs ${websiteStatus === 'Online' ? 'bg-lime-400/20 text-lime-700' : 'bg-pink-400/15 text-pink-700'}`}>
            {websiteStatus === 'Online' ? (
              <>
                <ArrowUpIcon className="h-3 w-3 text-lime-700" />
                {uptimePercentage !== null && `${uptimePercentage}%`}
              </>
            ) : (
              <>
                <ArrowDownIcon className="h-3 w-3 text-pink-700" />
                {uptimePercentage !== null && `${uptimePercentage}%`}
              </>
            )}
          </span>
        </div>
      </div>

      {/* Placeholder for future cards */}
      <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
        <div className="flex justify-between items-center">
          <h2 className="font-medium text-zinc-900">Updates beschikbaar</h2>
        </div>
        <div className="mt-3 text-3xl font-semibold sm:text-2xl">
          3
        </div>
      </div>

      <div className="bg-white dark:bg-zinc-900 shadow rounded-lg p-4">
        <div className="flex justify-between items-center">
          <h2 className="font-medium text-zinc-900">Updates uitgevoerd</h2>
        </div>
        <div className="mt-3 text-3xl font-semibold sm:text-2xl">
          12
        </div>
      </div>
    </div>
  );
};

export default TechnicalTab;
