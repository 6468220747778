import { useState, useEffect } from 'react';
import { workspaceService } from '../services/workspace.service';

export const useWorkspace = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWorkspaces = async () => {
    try {
      setLoading(true);
      const data = await workspaceService.getWorkspaces();
      setWorkspaces(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  const createWorkspace = async (name, websiteUrl, userId) => {
    try {
      await workspaceService.createWorkspace(name, websiteUrl, userId);
      await fetchWorkspaces();
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const updateWorkspace = async (id, updates) => {
    try {
      await workspaceService.updateWorkspace(id, updates);
      await fetchWorkspaces();
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const deleteWorkspace = async (id) => {
    try {
      await workspaceService.deleteWorkspace(id);
      await fetchWorkspaces();
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  return {
    workspaces,
    loading,
    error,
    createWorkspace,
    updateWorkspace,
    deleteWorkspace,
    refreshWorkspaces: fetchWorkspaces
  };
}; 