import { Fragment, useState, useEffect } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useAuth } from '../hooks/useAuth';
import { Avatar } from '../components/catalyst/avatar';
import { 
  HomeIcon, 
  UsersIcon, 
  FolderIcon,
  Cog8ToothIcon,
  ChevronDownIcon,
  ArrowRightStartOnRectangleIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { supabase } from '../api/supabase/client';

const navigation = [
  { name: 'Dashboard', href: '/workspaces', icon: HomeIcon },
  { name: 'Users', href: '/admin/users', icon: UsersIcon },
  { name: 'Settings', href: '/settings', icon: Cog8ToothIcon },
];

export function StackedLayout() {
  const { user, signOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [workspaces, setWorkspaces] = useState([]);
  const [currentWorkspace, setCurrentWorkspace] = useState(null);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      if (!user) return;

      const { data: workspaceUsers, error: workspaceUserError } = await supabase
        .from('workspace_users')
        .select('workspace_id')
        .eq('user_id', user.id);
      
      if (workspaceUserError) {
        console.error('Error fetching workspace access:', workspaceUserError);
        return;
      }

      const workspaceIds = workspaceUsers.map(wu => wu.workspace_id);

      if (workspaceIds.length > 0) {
        const { data: workspacesData, error: workspaceError } = await supabase
          .from('workspaces')
          .select('*')
          .in('id', workspaceIds);

        if (workspaceError) {
          console.error('Error fetching workspaces:', workspaceError);
        } else {
          setWorkspaces(workspacesData);
          // Set first workspace as current if none selected
          if (!currentWorkspace && workspacesData.length > 0) {
            setCurrentWorkspace(workspacesData[0]);
          }
        }
      }
    };

    fetchWorkspaces();
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleWorkspaceSelect = (workspace) => {
    setCurrentWorkspace(workspace);
    navigate(`/workspace/${workspace.id}/overview`);
  };

  return (
    <>
      <div className="min-h-screen bg-gray-50">
        <div className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className="flex flex-shrink-0 items-center">
                  <Menu as="div" className="relative">
                    <Menu.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                      <span className="text-xl font-bold">
                        {currentWorkspace?.name || 'Select Workspace'}
                      </span>
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {workspaces.map((workspace) => (
                            <Menu.Item key={workspace.id}>
                              {({ active }) => (
                                <button
                                  onClick={() => handleWorkspaceSelect(workspace)}
                                  className={clsx(
                                    active ? 'bg-gray-100' : '',
                                    'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                  )}
                                >
                                  {workspace.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/workspaces"
                                className={clsx(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                All Workspaces
                              </Link>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={clsx(
                        'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
                        location.pathname === item.href
                          ? 'border-indigo-500 text-gray-900'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                      )}
                    >
                      <item.icon className="mr-3 h-5 w-5" />
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex items-center">
                <Menu as="div" className="relative ml-3">
                  <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <Avatar 
                      initials={user?.email?.charAt(0).toUpperCase() || 'U'} 
                      className="h-8 w-8 bg-black text-white"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/profile"
                            className={clsx(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleSignOut}
                            className={clsx(
                              active ? 'bg-gray-100' : '',
                              'block w-full px-4 py-2 text-left text-sm text-gray-700'
                            )}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <div className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
} 