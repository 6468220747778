import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWorkspace } from '../hooks/useWorkspace';
import { useAuth } from '../hooks/useAuth';
import { Button } from '../components/catalyst/button';
import { Heading } from '../components/catalyst/heading';
import { Avatar } from '../components/catalyst/avatar';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../components/catalyst/dropdown';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import AddWorkspaceDialog from '../components/workspace/AddWorkspaceDialog';
import EditWorkspaceDialog from '../components/workspace/EditWorkspaceDialog';
import AssignUserDialog from '../components/workspace/AssignUserDialog';

const WorkspacesPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { workspaces, loading, error, createWorkspace, updateWorkspace, deleteWorkspace } = useWorkspace();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  console.log('Workspaces:', workspaces); // Debug log

  if (loading) return (
    <div className="flex items-center justify-center min-h-[400px]">
      <p>Loading workspaces...</p>
    </div>
  );

  if (error) return (
    <div className="flex items-center justify-center min-h-[400px]">
      <p className="text-red-500">Error: {error.message}</p>
    </div>
  );

  const handleWorkspaceClick = (workspaceId) => {
    navigate(`/workspace/${workspaceId}/overview`);
  };

  const handleEditClick = (workspace) => {
    setSelectedWorkspace(workspace);
    setIsEditDialogOpen(true);
  };

  const handleAssignClick = (workspace) => {
    setSelectedWorkspace(workspace);
    setIsAssignDialogOpen(true);
  };

  const handleDeleteClick = async (workspaceId) => {
    if (window.confirm('Weet je zeker dat je deze workspace wilt verwijderen?')) {
      await deleteWorkspace(workspaceId);
    }
  };

  return (
    <div className="py-8">
      <div className="flex justify-between items-center mb-6">
        <Heading>Workspaces</Heading>
        <Button onClick={() => setIsAddDialogOpen(true)}>
          Nieuwe Workspace
        </Button>
      </div>

      {workspaces.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-500 mb-4">Je hebt nog geen workspaces.</p>
          <Button onClick={() => setIsAddDialogOpen(true)}>
            Maak je eerste workspace aan
          </Button>
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
          {workspaces.map((workspace) => (
            <div
              key={workspace.id}
              className="relative rounded-lg border border-gray-200 bg-white p-6 shadow-sm hover:shadow-md transition-shadow duration-200"
            >
              <div className="flex justify-between items-start">
                <div 
                  className="cursor-pointer flex-grow"
                  onClick={() => handleWorkspaceClick(workspace.id)}
                >
                  <h3 className="text-lg font-semibold text-gray-900">
                    {workspace.name}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {workspace.website_url}
                  </p>
                </div>
                <Dropdown>
                  <DropdownButton>
                    <EllipsisHorizontalIcon className="h-5 w-5 text-gray-500" />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleEditClick(workspace)}>
                      Bewerken
                    </DropdownItem>
                    <DropdownItem onClick={() => handleAssignClick(workspace)}>
                      Gebruikers toewijzen
                    </DropdownItem>
                    <DropdownItem 
                      onClick={() => handleDeleteClick(workspace.id)}
                      className="text-red-600"
                    >
                      Verwijderen
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="mt-4 flex -space-x-2">
                {workspace.users?.map((user, index) => (
                  <Avatar
                    key={index}
                    initials={user.initials}
                    className="inline-block h-8 w-8 rounded-full bg-black text-white"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      <AddWorkspaceDialog
        isOpen={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onWorkspaceAdded={createWorkspace}
        userId={user?.id}
      />

      {selectedWorkspace && (
        <>
          <EditWorkspaceDialog
            isOpen={isEditDialogOpen}
            onClose={() => {
              setIsEditDialogOpen(false);
              setSelectedWorkspace(null);
            }}
            workspace={selectedWorkspace}
            onWorkspaceUpdated={updateWorkspace}
          />

          <AssignUserDialog
            isOpen={isAssignDialogOpen}
            onClose={() => {
              setIsAssignDialogOpen(false);
              setSelectedWorkspace(null);
            }}
            workspaceId={selectedWorkspace.id}
          />
        </>
      )}
    </div>
  );
};

export default WorkspacesPage;
