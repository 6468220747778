import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { StackedLayout } from './layouts/StackedLayout';
import WorkspaceLayout from './layouts/WorkspaceLayout';
import Workspaces from './pages/Workspaces';
import Users from './pages/admin/Users';
import WorkspaceOverview from './pages/workspace/Overview';
import WorkspaceSettings from './pages/workspace/Settings';
import Login from './pages/auth/Login';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/workspaces" replace />} />
        
        <Route
          element={
            <ProtectedRoute>
              <StackedLayout />
            </ProtectedRoute>
          }
        >
          {/* Admin routes */}
          <Route path="/admin/users" element={<Users />} />

          {/* Workspaces overzicht */}
          <Route path="/workspaces" element={<Workspaces />} />

          {/* Workspace specifieke routes */}
          <Route path="/workspace/:id/*" element={<WorkspaceLayout />}>
            <Route index element={<Navigate to="overview" replace />} />
            <Route path="overview" element={<WorkspaceOverview />} />
            <Route path="settings" element={<WorkspaceSettings />} />
          </Route>

          {/* Catch all */}
          <Route path="*" element={<Navigate to="/workspaces" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;